<template>
  <v-container fluid>
    <!-- Section Path File/Modul -->
    <v-breadcrumbs 
      :items="pathModul"
      divider="-"
      normal
      class="pathModul"
    ></v-breadcrumbs>

    <!-- Section Filtering Input/Select/Button -->
    <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">

      <v-row align="center">
          
        <v-col
        class="col-input-filter"
        :cols="$vuetify.breakpoint.lgAndUp? '' : '12'"
        >
            <v-select
            v-on:change="selectValueStation"
            :items="itemStation"
            item-text="stationdisplay"
            item-value="stationvalue"
            v-model="stnSelect"
            label="Station"
            prepend-inner-icon="mdi-store-marker"
            hint="Station"
            solo
            dense
            hide-details
            ></v-select>
        </v-col>

        <!-- Column Select Parameter -->
        <v-col
        class="col-input-filter"
        :cols="$vuetify.breakpoint.lgAndUp? '' : '12'"
        >
            <v-select
            v-model="arraySelectedParam"
            :items="listParam"
            item-text="paramdisplay"
            item-value="paramvalue"
            label="Parameter"
            prepend-inner-icon="mdi-beaker-outline"
            multiple persistent-hint
            hide-details solo dense
            @change="checkedParam($event)"
            ></v-select>
        </v-col> 

        <!-- Column Select Calendar -->
        <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'" class="col-input-filter col-dateYear" >
          <v-select
          v-on:change="selectValueDate"
          :items="dropdownDate"
          prepend-inner-icon="mdi-calendar-today"
          label="Year"
          class="input-date-year"
          hide-details solo dense
          >
          </v-select>
        </v-col>

        <!-- Column Generate Button -->
        <v-col cols="12" sm="6" md="4">
          <v-btn v-if="this.$store.getters.user.role == 5"
          class="white--text button mt-3"
          solo dense normal
          :loading="loadingReport"
          @click="generateChart"
          :disabled="arraySelectedParam == '' || valDate == null"
          >
            GENERATE REPORT
          </v-btn>
          <v-btn v-else
          class="white--text button mt-3"
          solo dense normal
          :loading="loadingReport"
          @click="generateChart"
          :disabled="valStation == null || arraySelectedParam == '' || valDate == null"
          >
            GENERATE REPORT
          </v-btn>
        </v-col>

      </v-row>

      <v-card>
        <!-- Main Report Title/Tab -->
        <v-tabs>
            <v-tab>YEARLY</v-tab>
        </v-tabs>

        <div :style="$vuetify.breakpoint.lgAndUp? 'max-height: calc(100vh - 360px);overflow-y:auto;overflow-x:hidden;' : ''">
          <div v-show="showChart">

            <highcharts 
            :constructorType="'stockChart'" 
            class="hc" 
            :options="chartOptions" 
            ref="chart"
            >
            </highcharts>

          </div>
          

          <v-divider class="pb-4"></v-divider>

          <!---- Export Button ---->
          <v-row class="justify-end px-10 pb-0">
            <div v-show='showExportBtn' v-if="this.$store.getters.user.idUserAccess !== 3">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="excel"
                  class="mb-5"
                  style="float: right;"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportExcel()"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                        mdi-microsoft-excel
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to Excel</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="pdf"
                  class="mb-5 mx-2"
                  style="float: right;"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportReport('pdf')"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                        mdi-file-pdf-box
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to PDF</span>
              </v-tooltip> -->
              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="csv"
                  class="mb-5 mx-2"
                  style="float: right;"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportCSV()"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                        mdi-file-delimited
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to CSV</span>
              </v-tooltip>
            </div>
          </v-row>

          <v-data-table
          :headers="dtheader"
          :items="dtbody"
          :items-per-page="itemPerPage"
          class="elevation-1 px-6 headerDtSarawak headerDesktop"
          fixed-header
          :mobile-breakpoint="0" 
          ></v-data-table>
        </div>

        <template>
          <v-footer padless>
            <v-col
              class="text-center footer-report"
              cols="12"
            >
              <!-- {{ new Date().getFullYear() }} — <strong>Vuetify</strong> -->
              <span class="one-text-footer">
                <span class="red--text">C</span>
                <span class="blue--text">=CALIBRATION</span>
              </span>
              <span class="one-text-footer">
                <span class="red--text">M</span>
                <span class="blue--text">=MAINTENANCE</span>
              </span>
              <span class="one-text-footer dontBreak">
                <span class="red--text">DOR</span>
                <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
              </span><br>
              <span class="one-text-footer">
                <span class="red--text">OUTL</span>
                <span class="blue--text">=OUTLIER</span>
              </span>
              <span class="one-text-footer">
                <span>NA</span>
                <span class="blue--text">=DATA NOT AVAILABLE</span>
              </span>

            </v-col>
          </v-footer>
        </template>

      </v-card>      

    </v-container>

    <v-dialog 
      v-model="dialogAlert"
      width="500">

      <v-card>
        <v-card-title class="text-h5 white--text error">
            <v-icon class="white--text mr-4">mdi-alert</v-icon>Attention
        </v-card-title>

        <v-card-text class="pa-5 d-flex justify-center">
            <h3>Due to performance issue, you can only select maximum of 2 parameters only.</h3> 
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialogAlert = false;"
          >
              Close
          </v-btn>
        </v-card-actions>
      </v-card>
    
    </v-dialog>

  </v-container>
</template>


<script>

import axios from 'axios';
import Papa from "papaparse";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    
  data: () => ({
    itemPerPage: 10,
    showExportBtn: false,
    dialogAlert: false,
    GlobalDataYearly : [],
    loadingReport: false,
    pathModul: [
        {
            text: 'Home',
            disabled: false,
            href: '/Dashboard',
            // href: '/Mapviewer',
        },
        {
            text: 'Report - Yearly',
            disabled: true,
            href: '/Report/YearlyReport',
        },
    ],
    itemStation: ['All Station'],
    dropdownDate: [],
    valStation: null,
    valParameter: null,
    valDate: null,
    stationTitle: null,
    excol: '',
    excols: '',
    stnSelect: '',

    // Title
    locationStation: null,
    stationID: null,

    arraySelectedParam: [],
    listParam: [],

    chartOptions: {
      // chart: {
      //   type: 'spline'
      // },
      title: {
        text: 'WATER QUALITY STATUS'
      },
      subtitle: {
        text: ''
      },
      series: [],
      legend: {
          enabled: true
      },
    },
    generalUser: false,
    dtbody: [],
    dtheader: [],
    mainParam: [],
    unit: [],
    showChart: false
      
  }),

  methods: {

    convert12Hours(time) {
      var H = +time.substr(0, 2);
      var h = H % 12 || 12;
      var ampm = (H < 12 || H === 24) ? "AM" : "PM";
      time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
      return time;
    },

    convertDateFormat(date){
      var dd = date.split("-")[2];
      var mm = date.split("-")[1];
      var yyyy = date.split("-")[0];
      date = mm + "/" + yyyy;
      return date;
    },

    load(){
      // this.getDataParam();
      this.getApiStation();
      this.getApiDateYear();
      this.getApiListParameter();
    },

    getApiStation(){
      axios.get(this.globalUrl+'bakaj/stations', {
          headers: {
              'Authorization': 'Bearer ' + this.$store.getters.user.token,
          }
      })
      .then((response) => {


          if(this.$store.getters.user.role == 5){
            this.itemStation = [];
          }

        
          // this.stationDetails = response.data;
          for (let i = 0; i < response.data.length; i++) {
            if(this.$store.getters.user.role == 5){

              // this.itemStation = [];

              let obj;
              
              if (i > 0 && i < 4 ){
              
             obj = {
                 stationdisplay: response.data[i].stationId + " - " + response.data[i].location,
                 stationvalue : response.data[i].stationId
               }
               if(i == 1) { this.stnSelect = obj }
             }

             if (obj !== undefined){
              this.itemStation.push(obj);
             }

            } else {

              this.itemStation.push(response.data[i].stationId + ' - ' + response.data[i].location);

            }
          }
          
          // console.log("stn",this.itemStation);

      })
      .catch(error => {
          console.log(error);
      })
    },

    getApiDateYear(){
      axios.get(this.globalUrl+'bakaj/years', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            // this.stationDetails = response.data;
            console.log(response.data);
            for (let i = 0; i < response.data.length; i++) {
              this.dropdownDate.push(response.data[i]);
            }

            
            // console.log(this.itemStation);

        })
        .catch(error => {
            console.log(error);
        })
    },

    getApiListParameter() {

      // console.log("masuk")

      this.add_par = [];
      axios
      .get(this.globalUrl+'bakaj/params', {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {
          this.mainParam = response.data;

          for(let k in response.data){

            if (response.data[k].reading ==  "SI_DO_SAT" || response.data[k].reading == "SI_TSS" || response.data[k].reading == "SI_pH")
            {
              let obj_par = {
                paramdisplay: response.data[k].text,
                paramvalue: response.data[k].reading
              }

              this.add_par.push(obj_par);
            }


          }
      })
      .catch((error) => {
        console.log(error);
      });

      // console.log("unit", this.add_par)
    },

    getDataParam(stn){

      this.unit = [];
      this.listParam = [];
      // console.log(this.valStation)
      let stn_id;
      if(this.valStation !== null){
        stn_id = (this.valStation).split("-")[0];

      }
      // console.log("sid",stn_id)

      this.valStation !== null ? stns = stn_id : stns = stn

      let urllink;
      let stns;

      stn_id == "All Station" ? urllink = 'bakaj/params' : urllink = 'bakaj/params?stationid='+stns

      // console.log(urllink)

      axios.get(this.globalUrl+urllink, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            let data = response.data;
            //var obj;

            let param = [];
            for(let k in data){

              let obj = {
                paramdisplay: data[k].text,
                paramvalue : data[k].reading
              }

              // if(data[k].reading === 'pH'){
              //   obj = {
              //       paramdisplay: 'Sub-index '+ data[k].fullname +' (Index)',
              //       paramvalue : data[k].reading
              //   }
              // }

              // else if(data[k].reading === 'DO_SAT'){
              //   obj = {
              //       paramdisplay: 'Sub-index Dissolved Oxygen (Index)',
              //       paramvalue : data[k].reading
              //   }
              // }

              // else if(data[k].reading === 'TSS'){
              //   obj = {
              //       paramdisplay: 'Sub-index '+ data[k].fullname +' (Index)',
              //       paramvalue : data[k].reading
              //   }
              // }

              // else{
              //   obj = {
              //       paramdisplay: data[k].text,
              //       paramvalue : data[k].reading
              //   }
              // }

              let objunit = {
                  name: data[k].reading,
                  value: data[k].unit
              }

              param.push(obj);
              this.unit.push(objunit);
            }

            let siph = {
              name: "SI_pH",
              value: "Index"
            }

            let sitss = {
              name: "SI_TSS",
              value: "Index"
            }

            let sido = {
              name: "SI_DO_SAT",
              value: "Index"
            }

            this.unit.push(siph,sitss,sido);
            this.listParam = param;
            this.listParam.push(...this.add_par);

            // for (let i = 0; i < response.data.length; i++) {
            //   this.listParam.push(response.data[i].reading);
            // }

        })
        .catch(error => {
            console.log(error);
        })
    },

    // Get Data From Api
    getDataYearly(station,tarikh){

        if(this.valParameter.length > 2) 
        {
          alert('Sorry, due to performance issue, you can only select max 2 params only.');
          this.loadingReport = false;
          return;
        }

        axios.get(this.globalUrl+'bakaj/yearly?startdt='+ this.valDate +'-01-01&stationid=' + station.replaceAll('All Station','all') + '&paramlist=' + this.valParameter, {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            // Globalkan Data
            this.GlobalDataYearly = response.data;
            this.loadDataChart(response.data,this.valParameter,station);

        })
        .catch(error => {
            console.log(error);
        })
    },


    // Get value from Selected Station
    selectValueStation: function(e) {
      this.valStation = e;
      this.getDataParam();
    },

    // Get value from Selected Year
    selectValueDate: function(e) {
      this.valDate = e
    },

    // Get value from Selected Parameters
    checkedParam: function(e) {
      if(e.length > 2){
        this.dialogAlert = true;
        this.arraySelectedParam .splice(2, 1);
      }

      else{
        this.valParameter = e;
      }
    },

    // Get value from Selected Date (by Year)
    selectDate: function(e) {
      this.valDate = e;
    },

    // load Data for Rendering Data Series Highchart
    loadDataChart(data,param,stesen){

      this.dtbody = [];
      this.chartOptions.series = [];

      // console.log("ss",this.valStation)

      if (this.valStation == 'All Station'){

        this.chartOptions.title.text = "WATER QUALITY STATUS FOR ALL STATIONS " +this.valDate;

      } else {

        this.chartOptions.title.text = "WATER QUALITY STATUS FOR "+ this.valStation.split("-")[1].toUpperCase() + " ( "+ this.valStation.split("-")[0] + ") " +this.valDate;

      }
      
      this.excol = this.valParameter.length + 3
      this.excols = this.valParameter.length + 1

      for (let i = 0; i < data.length; i++) {
        let keyData = Object.keys(data[0])
      }

      this.dtheader = [];
      for(let i of Object.keys(data[0])){
        if(i != "name" && i != "time" && i != "epochdate" ){
          if(i == "datetime_a"){
            this.dtheader.push({text:"DATE",value:i,width:'10%'});
          }

          if (this.valStation == 'All Station'){
             if(i == "station_a"){
              this.dtheader.push({text:"STATION ID",value:i,width:'10%'});
            }

            else if(i == "LOCATION"){
              this.dtheader.push({text:"LOCATION",value:i,width:'10%'});
            }
          }

          // else{
          //   this.dtheader.push({text:i,value:i});
          // }
        }
      }

      for(let j in this.arraySelectedParam){
        for(let k in this.mainParam){
          if(this.arraySelectedParam[j] === this.mainParam[k].reading){

            // if(this.arraySelectedParam[j] === 'pH'){
            //   this.dtheader.push({text:'Sub-index pH (Index)',value:this.mainParam[k].reading});
            // }

            // else if(this.arraySelectedParam[j] === 'DO_SAT'){
            //   this.dtheader.push({text:'Sub-index Dissolved Oxygen (Index)',value:this.mainParam[k].reading});
            // }

            // else if(this.arraySelectedParam[j] === 'TSS'){
            //   this.dtheader.push({text:'Sub-index Total Suspended Solids (Index)',value:this.mainParam[k].reading});
            // }

            // else{
            //   this.dtheader.push({text:this.mainParam[k].fullname,value:this.mainParam[k].reading});
            // }

            this.dtheader.push({text:this.mainParam[k].text,value:this.mainParam[k].reading,width:'20%'});

          }
        }
      }

      for(let i in data){

        data[i]["datetime_a"] = this.convertDateFormat(data[i]["datetime_a"].split("T")[0]);

        if (data[i]["SI_DO_SAT"] === null) { data[i]["SI_DO_SAT"] = "NA"; }
        
        if (data[i]["DO_SAT"] != null && typeof data[i]["DO_SAT"] == "number") { data[i]["DO_SAT"] = data[i]["DO_SAT"].toFixed(2); }
        else{ data[i]["DO_SAT"] = "NA"; }

        if (data[i]["DO_CON"] != null && typeof data[i]["DO_CON"] == "number") { data[i]["DO_CON"] = data[i]["DO_CON"].toFixed(2); }
        else{ data[i]["DO_CON"] = "NA"; }

        if (data[i]["SI_pH"] === null) { data[i]["SI_pH"] = "NA"; }

        if (data[i]["pH"] != null && typeof data[i]["pH"] == "number") { data[i]["pH"] = data[i]["pH"].toFixed(2); }
        else{ data[i]["pH"] = "NA"; }

        if (data[i]["SI_TSS"] === null) { data[i]["SI_TSS"] = "NA"; }

        if (data[i]["TSS"] != null && typeof data[i]["TSS"] == "number") { data[i]["TSS"] = data[i]["TSS"].toFixed(1); }
        else{ data[i]["TSS"] = "NA"; }

        if (data[i]["TEMPERATURE"] != null && typeof data[i]["TEMPERATURE"] == "number") { data[i]["TEMPERATURE"] = data[i]["TEMPERATURE"].toFixed(2); }
        else{ data[i]["TEMPERATURE"] = "NA"; }

        if (data[i]["EC"] != null && typeof data[i]["EC"] == "number") { data[i]["EC"] = data[i]["EC"].toFixed(2); }
        else{ data[i]["EC"] = "NA"; }

        if (data[i]["SALINITY"] != null && typeof data[i]["SALINITY"] == "number") { data[i]["SALINITY"] = data[i]["SALINITY"].toFixed(2); }
        else{ data[i]["SALINITY"] = "NA"; }

        if (data[i]["TDS"] != null && typeof data[i]["TDS"] == "number") { data[i]["TDS"] = data[i]["TDS"].toFixed(2); }
        else{ data[i]["TDS"] = "NA"; }

        if (data[i]["TURBIDITY"] != null && typeof data[i]["TURBIDITY"] == "number") { data[i]["TURBIDITY"] = data[i]["TURBIDITY"].toFixed(1); }
        else{ data[i]["TURBIDITY"] = "NA"; }

        if (data[i]["NH4"] != null && typeof data[i]["NH4"] == "number") { data[i]["NH4"] = data[i]["NH4"].toFixed(2); }
        else{ data[i]["NH4"] = "NA"; }

        if (data[i]["NO3"] != null && typeof data[i]["NO3"] == "number") { data[i]["NO3"] = data[i]["NO3"].toFixed(2); }
        else{ data[i]["NO3"] = "NA"; }

        if (data[i]["OIL_IN_WATER"] != null && typeof data[i]["OIL_IN_WATER"] == "number") { data[i]["OIL_IN_WATER"] = data[i]["OIL_IN_WATER"].toFixed(4); }
        else{ data[i]["OIL_IN_WATER"] = "NA"; }

        if (data[i]["PAH_HYDROCARBON"] != null && typeof data[i]["PAH_HYDROCARBON"] == "number") { data[i]["PAH_HYDROCARBON"] = data[i]["PAH_HYDROCARBON"].toFixed(4); }
        else{ data[i]["PAH_HYDROCARBON"] = "NA"; }

        if (data[i]["WATER_LEVEL"] != null && typeof data[i]["WATER_LEVEL"] == "number") { data[i]["WATER_LEVEL"] = data[i]["WATER_LEVEL"].toFixed(2); }
        else{ data[i]["WATER_LEVEL"] = "NA"; }

        if (data[i]["VELOCITY"] != null && typeof data[i]["VELOCITY"] == "number") { data[i]["VELOCITY"] = data[i]["VELOCITY"].toFixed(2); }
        else{ data[i]["VELOCITY"] = "NA"; }

        if (data[i]["FLOWRATE"] != null && typeof data[i]["FLOWRATE"] == "number") { data[i]["FLOWRATE"] = data[i]["FLOWRATE"].toFixed(2); }
        else{ data[i]["FLOWRATE"] = "NA"; }

        if (data[i]["NH3"] != null && typeof data[i]["NH3"] == "number") { data[i]["NH3"] = data[i]["NH3"].toFixed(4); }
        else{ data[i]["NH3"] = "NA"; }

        this.dtbody.push(data[i]);
      }

      // Clear first chart before generate another new chart
      //this.chartOptions.series = [];

      // Create object data to put in series chart formating 
      for(let i = 0; i < param.length; i++){
          for(let k in this.unit){

            // console.log(this.unit[k].name);
            // console.log("selected: "+param[i]);

            if(param[i] === this.unit[k].name){
              
              let obj;

              if(this.unit[k].name != null){
                obj = {
                  name: param[i] + " " + ("["+this.unit[k].value+"]" ?? ""),
                  data: []
                }
              }

              else{
                obj = {
                  name: param[i],
                  data: []
                }
              }
              
              data.forEach(function(res,k){

                if(res[param[i]] != null){
                  obj.data.push([res.epochdate,Number(res[param[i]])]);
                }

                else{
                  obj.data.push([res.epochdate,res[param[i]]]);
                }
                
              });
              this.chartOptions.series.push(obj);
            }
          }
        }

      this.loadingReport = false;

    },

    exportCSV(){

      let headerExportCsv = [];
      for(let i in this.dtheader){
        headerExportCsv.push(this.dtheader[i]['value']);
      }

      let keys = headerExportCsv,
      resultAfterFilter = this.dtbody.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

      let obj = resultAfterFilter;
      

      for(let i in obj){

        obj[i]["DATE"] =  obj[i]['datetime_a']; 
        // obj[i]["STATION ID"] =  obj[i]['station_a']; 
        // obj[i]["LOCATION "] =  obj[i]['LOCATION']; 

        delete obj[i]['datetime_a'];
        if (this.valStation !== 'All Station'){
          delete obj[i]['station_a'];
          delete obj[i]['LOCATION'];
        }

        for(let j in this.mainParam){
          for(let k in this.arraySelectedParam){
            if(this.arraySelectedParam[k] === this.mainParam[j].reading){
              obj[i][this.mainParam[j].text] = obj[i][this.mainParam[j].reading];
              delete obj[i][this.mainParam[j].reading];
            }
          }
        }

      }

      const BOM = '\uFEFF';
      let blob = new Blob([BOM+Papa.unparse(obj)], { type: 'text/csv;charset=utf-8;' });
      let link = document.createElement("a");
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", 'Yearly Report, '+this.valDate+' - '+this.valStation+'.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    },

    exportExcel(){

      this.itemPerPage = -1;

      // Define your style class template.
      let headerName;

      if (this.valStation == 'All Station'){

        headerName = "<tr = 'border:none !important' ><td colspan ='"+ this.excol +"' style = 'text-align:center; border:none !important; font-weight: bold'>WATER QUALITY STATUS FOR ALL STATION " +this.valDate + "</td></tr>"

      } else {

         headerName = "<tr = 'border:none !important' ><td colspan ='"+ this.excols +"' style = 'text-align:center; border:none !important; font-weight: bold'>WATER QUALITY STATUS FOR "+ this.valStation.split("-")[1].toUpperCase() + " ( "+ this.valStation.split("-")[0] + ") " +this.valDate + "</td></tr>"

      }

        
        var style = "<style> th, td { border: 10px solid;}</style>";

      var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' + style + '</head><body><table>' + headerName + '{table}</table></body></html>'
      , base64 = function (s) {
          return window.btoa(unescape(encodeURIComponent(s)))
      }
      , format = function (s, c) {
          return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
      }

      setTimeout(() => {

        let table = document.querySelectorAll('.v-data-table__wrapper ')
        // console.log("hehe", table[0].innerHTML, table, document.querySelectorAll('.v-data-table__wrapper table'))

        let tablei = table[0].innerHTML
          // if (tablei.includes("℃")){
            tablei = tablei.replaceAll("°C","&#176;C").replaceAll("µ","&#181;")
          // }

        let ctx = { worksheet: "YEARLY REPORT" || 'Worksheet', table: tablei }
        // window.location.href = uri + base64(format(template, ctx))

        let a = document.createElement('a');
        a.href = uri + base64(format(template, ctx))
        a.download = 'Yearly Report.xls';
        //triggering the function
        a.click();

        this.itemPerPage = 10;

      }, 1000)

      
    },

    // Generate Chart
    generateChart(){
      this.loadingReport = true;
      this.showExportBtn = true;
      this.showChart = false;
      this.$store.getters.user.role == 3 ? this.generalUser == true : this.generalUser == false; 

      if(this.$store.getters.user.role == 5){
          if(this.valStation == null)  { this.valStation = "WQMS02 - Sg. Semanggar" }
        }

      if (this.valStation !== 'All Station'){
        this.showChart = true;
      }
      this.getDataYearly(this.valStation.split("-")[0],this.valDate);
    },

    handler() {
      var args = arguments;
      for (var arg of args) {
        if (arg instanceof Function) {
          arg();
        }
      }
    },
    
  },

  mounted() {
    this.load();
    if(this.$store.getters.user.role == 5){
      this.getDataParam('WQMS02')
      }
  }

}
</script>



<style>

     /* Section Path File/Modul */
    .pathModul{
      padding-bottom: 0px;
    }

    .pathModul > li:nth-child(3) > a{
      color: black !important;
    }

    /* Section Filtering Input/Select/Button */
    .container-filtering{
      padding: 0px;
    }

    /* .col-input-filter{
      padding: 0px 10px;
    } */
    .col-dateYear{
      display: flex !important;
    }

    .input-date-year{
      margin-left: 12px;
    }

    .col-btn-generateReport{
        margin-bottom: 18px;
    }

    .btn-generate{
        margin: 0px !important;
        /* background: #4495D1 !important; */
    }

    .v-text-field.v-text-field--solo .v-input__control,
    .btn-generate .v-btn .v-btn--contained{
        min-height: 10px;
        margin-top: 10px;
    }

    /* Section Chart Form */
    .highcharts-credits{
      display: none;
    }

    /* Footer Report */
    .one-text-footer{
      margin: 0px 10px;
    }


</style>